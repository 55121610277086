<script>
    import Advertising from '@/services/Advertising'
    import validationMessages from '@/components/validations'
    import { required } from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        props: {
            sponsor_id: {
                type: String,
                default: null
            },
        },
        validations: {
            attribute_name:{
                required,
            },
            attribute_value:{
                required,
            },
        },
        data() {
            return {
                showModal: false,
                submitted: false,
                attribute_name: null,
                attribute_value: null,
                tryingToSubmit: false,
                csrf_token: localStorage.getItem('csrf_token')
            };
        },
        methods: {
            addAttributes(){
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    Advertising.createSponsorAttributes(this.sponsor_id, {
                        attributes: [
                            {
                                attribute:  this.attribute_name,
                                value:  this.attribute_value
                            }
                        ],
                        csrf_token: this.csrf_token
                    })
                    .then((response) => {
                        const res = response.data.data ? response.data.data : false;
                        const error = response.data.error ? response.data.error : '';
                        if(res){
                            this.successmsg('Attribute Created!');
                            this.$emit('refreshScreen')
                            this.closeModal();
                        }else{
                            this.failedmsg('Failed to create attribute')
                        }
                    })
                    .catch(error => {
                        const errorMsg = error.response ? error.response : 'Failed';
                        this.failedmsg(errorMsg)
                    })
                }
                this.tryingToSubmit = false;
            },
            closeModal() {
                this.showModal = false;
                this.submitted = false;
                this.attribute_name = null;
                this.attribute_value = null;
            },
        }
    };
</script>


<template>
    <b-modal id="add_sponsor_attributes" v-model="showModal" title="Add New Attribute" title-class="font-18" centered>
    <form @submit.prevent="addAttributes">
        <div class="row">
          <div class="col-12">

              <b-form-group label="Attribute Name" label-for="attributename" class="mb-3">
                <b-form-input
                        v-model="attribute_name"
                        id="attributename"
                        :class="{
                             'is-invalid': submitted && $v.attribute_name.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Attribute Name'" :validationName="$v.attribute_name"></validationMessages>
              </b-form-group>

              <b-form-group label="Attribute Value" label-for="attribute_value" class="mb-3">
                <b-form-input
                        id="attribute_value"
                        v-model="attribute_value"
                        :class="{
                            'is-invalid': submitted && $v.attribute_value.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Attribute Value'" :validationName="$v.attribute_value"></validationMessages>
              </b-form-group>

          </div>
        </div>

        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addAttributes" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
 
</template>