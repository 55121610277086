<script>
    import Swal from "sweetalert2"
    import Advertising from '@/services/Advertising'
    import ModalViewAdvertisement from "@/components/modals/advertising/modalViewAdvertisement";

    export default {
        components: { ModalViewAdvertisement },
        props: {
            sponsor_id: String
        },
        data() {
            return {
                tableData:[],
                advertisementData:[],
                isBusy: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 6,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: false,
                fields: [
                    {
                        key: "placement_id",
                        label: "Advertisement ID",
                        sortable: true,
                    },
                    {
                        key: "placement_name",
                        label: "Advertisement Type",
                        sortable: true,
                    },
                    {
                        key: "attributes",
                        label: "Advertisement Attributes"
                    },
                    {
                        key: "placement_active",
                        label: "Active",
                        sortable: true,
                    },
                    {
                        key: "placement_timestamp",
                        label: "Created",
                        sortable: true,
                    },
                    "action",
                ],
            }
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.media != null ? this.media.length : 0;
            }
        },
        mounted() {
            this.getSponsorPlacements()
            this.totalRows = 0;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            async getSponsorPlacements(){
                try {
                    this.filterData = true;
                    this.toggleBusy();
                    //var filters = this.getFilters()

                    const response = await Advertising.getSponsorPlacements(this.sponsor_id)
                    this.tableData = response.data.data
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                }
            },

            removeAdvertisement(placement_id){
                Swal.fire({
                    title: "Are you sure?",
                    text: "Remove From Advertisements !",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes!",
                }).then((result) => {
                    if (result.value) {
                        Advertising.deleteSponsorAdvertisement(placement_id)
                            .then((response) => {
                                const res   = response.data.data  ? response.data.data  : false;
                                const error = response.data.error ? response.data.error : '';
                                if(res && error==''){
                                    this.getSponsorPlacements()
                                    this.successmsg("Advertisement Successfully Removed")
                                }else{
                                    this.failedmsg("Advertisement Removal Failed")
                                }
                            })
                            .catch(error => {
                                this.failedmsg('Fail!',error)
                            });
                    }
                });
            },

            callmodalviewAdvertisement(data) {
                this.$bvModal.show("view_advertisement");
                this.advertisementData = data
            },
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive mb-0">
                <b-table
                    :items="tableData"
                    :busy="isBusy"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    empty-text="No Data Found"
                >

                    <template v-slot:cell(placement_name)="tableData">
                        <div >{{ tableData.item.placement_name }} ({{  tableData.item.placement_type_short }})</div>
                    </template>

                    <template v-slot:cell(attributes)="tableData">
                        <div v-for="(value, key) in tableData.item.attributes" :key="key">
                            {{ key }}: {{ value }}
                        </div>
                    </template>

                    <template v-slot:cell(placement_active)="data">
                        <div v-html="format_status(data.item.placement_active ? 'yes' : 'no')"></div>
                    </template>

                    <template v-slot:cell(action)="tableData">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a
                                        href="javascript:void(0);"
                                        @click="callmodalviewAdvertisement(tableData.item)"
                                        class="px-2 text-primary"
                                        title="View Advertisement"
                                >
                                    <i class="uil uil-eye font-size-18"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Advertisement" @click="removeAdvertisement(tableData.item.placement_id)">
                                    <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="col-12">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                    ></b-pagination>
                </ul>
            </div>
        </div>

        <ModalViewAdvertisement :advertisementData="advertisementData" ></ModalViewAdvertisement>
    </div>
</template>
