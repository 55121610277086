<script>
    import { required } from "vuelidate/lib/validators";
    import Advertising from '@/services/Advertising'
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            sponsorData: {
                type: Object,
                default: null
            },
        },
        watch: {
            sponsorData: function(newVal) {
                this.id_e = newVal.id;
                this.logo_e = newVal.logo;
                this.name_e = newVal.name;
                this.url_e = newVal.url;
                this.description_e = newVal.description;
                this.active_e = newVal.active;
                this.checked_active()
            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                id_e: '',
                logo_e: '',
                file_e: '',
                name_e: '',
                url_e: '',
                description_e: '',
                active_e: '',
                active_status: false,
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                image_removed: false
            };
        },
        validations: {
            name_e: {
                required,
            },
        },
        methods: {

            async editSponsor(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        let formData = new FormData()
                        formData.append('name', this.name_e)
                        formData.append('active', this.active_status == true ? 1 : 0)
                        formData.append('url', this.url_e)
                        formData.append('description', this.description_e)
                        formData.append('file', this.file_e)
                        formData.append('csrf_token', this.csrf_token)
                        formData.append('_method', 'PATCH')

                        if(this.image_removed && !this.file_e.length){
                            formData.append('logo', this.logo_e)
                        }

                        await Advertising.editSponsor(this.id_e, formData)
                            .then((response) => {
                            this.successmsg('Change has been saved');
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            checked_active(){
                this.active_status = this.active_e == 1 ? true : false;
            },

            selectFile(event){
                this.file_e = event.target.files[0]
                this.logo_e = URL.createObjectURL(this.file_e)
                this.image_removed = false
            },

            removeImage(){
                this.logo_e = ''
                this.file_e = ''
                this.$refs['file-input'].reset()
                this.image_removed = true
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.submitted = false
            }
        }
    };
</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="sponsor_edit" title="Edit Sponsor" title-class="font-18" centered>
        <form @submit.prevent="editSponsor">

            <b-form-group label-for="image" class="mb-3 text-center image-area">
                <img :src="logo_e != '' ? logo_e : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail me-2" >
                <b-form-file @change="selectFile" ref="file-input" type="file" class="mt-3" plain accept="image/jpeg, image/png" ></b-form-file>
                <a v-if="logo_e" class="remove-image" href="javascript:void(0);" @click="removeImage" style="display: inline;">&#215;</a>
            </b-form-group>

            <b-form-group label="Sponsor Name" label-for="formrow-sponsorname-input" class="mb-3">
                <b-form-input
                        v-model.trim="name_e"
                        type="text"
                        id="name_e"
                        aria-describedby="name_e-feedback"
                        :class="{
                              'is-invalid': submitted && $v.name_e.$error,
                            }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Sponsor Name'" :validationName="$v.name_e"></validationMessages>
            </b-form-group>

            <b-form-group label="Sponsor URL" label-for="formrow-url_e-input" class="mb-3">
                <b-form-input
                        v-model.trim="url_e"
                        type="text"
                        id="url_e"
                        aria-describedby="url_e-feedback"
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-textarea
                        v-model="description_e"
                        rows="3"
                        aria-describedby="description_e-feedback"
                 >
                </b-form-textarea>
            </b-form-group>

            <b-form-checkbox-group>
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="active_status"
                           type="checkbox"
                           class="form-check-input"
                           id="statusSponsor"
                    />
                    <label class="form-check-label" for="statusSponsor">Sponsor Status</label>
                </div>
            </b-form-checkbox-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editSponsor" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>